.rw__input {
    @extend .d-block;
    @extend .position-relative;
    input,
    .p-dropdown,
    .p-multiselect,
    textarea {
        border-color: variableValues($gray, "20");
        border-width: rem(1);
        border-style: solid;
        box-shadow: none !important;
        color: variableValues($gray, "70");
        outline: none;
        @extend .rounded-5;
    }
    input,
    p-dropdown,
    .p-dropdown,
    p-multiselect,
    .p-multiselect,
    p-inputtext,
    .p-inputtext,
    textarea {
        font-weight: 400;
        &:focus {
            border-color: variableValues($teal, "50") !important;
            & ~ label {
                font-size: rem(12);
            }
        }
        &:hover {
            border-color: variableValues($gray, "30");
        }
        &:active {
            border-color: variableValues($teal, "50");
        }
        &.p-filled,
        &.p-inputwrapper-filled {
            & ~ label {
                color: variableValues($gray, "60") !important;
                font-size: rem(12);
            }
        }
    }
    .p-dropdown-open {
        border-color: variableValues($teal, "50") !important;
        & ~ label {
            font-size: rem(12);
        }
    }
    label {
        @extend .rw__mb-0;
        color: variableValues($gray, "70");
        font-size: rem(16);
        margin-top: rem(-10);
    }
    .fa {
        background-size: contain !important;
        &-xmark {
            @extend .d-none;
        }
    }
    .p-inputnumber {
        width: 100%;
        &-buttons-stacked {
            .p-inputnumber-button-group {
                border: rem(1) solid variableValues($gray, "20");
                button {
                    width: rem(36);
                    span {
                        font-size: rem(12);
                    }
                }
            }
        }
    }
    .dropdown-menu {
        @extend .rounded-5;
        padding: 0;
        width: 100%;
        .dropdown-item {
            font-size: rem(14);
            height: rem(40);
            .rw__add-option {
                i {
                    font-size: rem(16) !important;
                }
            }
            &.active {
                background-color: variableValues($teal, "10");
                color: variableValues($teal, "60");
            }
            &:first-child {
                @extend .rounded-top-5;
            }
            &:last-child {
                @extend .rounded-bottom-5;
            }
        }
    }
    .p-dropdown {
        &-panel {
            width: 100%;
        }
        &-item,
        &-item > div {
            @extend .text-truncate;
        }
    }
    .p-calendar {
        width: 100%;
        &-clearable {
            .p-calendar-clear-icon {
                right: rem(14);
            }
        }
        &-w-btn {
            border-color: variableValues($gray, "20");
            box-shadow: none !important;
            .p-datepicker-trigger {
                background-color: variableValues($teal, "10") !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                .p-button-icon {
                    color: variableValues($teal, "50");
                }
            }
            .p-calendar-clear-icon {
                right: rem(60);
            }
        }
        .p-datepicker{
            
            border-radius: rem(4) !important;
            border: rem(1) solid variableValues($gray, '22') !important;
            background-color: #fff !important;
            box-shadow: 0 rem(4) rem(6) rgba(black, 0.10) !important;

            &-header{
                .p-datepicker-prev, .p-datepicker-next{
                    &-icon{
                        font-size: rem(12);

                    }
                    &-enabled{
                        color: #5C7070;
                        &:hover{
                            background-color: #F4F6F6;
                        }
                    }
                }
                .p-datepicker-title{
                    .p-link{
                        font-size: rem(14);
                        font-weight: 400;
                        line-height: rem(20);
                        color: #445555;
                        background-color: #F4F6F6;
                        border-radius: rem(4);
                        &:hover{
                            color: #445555;
                        }
                    }
                    .p-datepicker-month{
                        padding: 6px 16px;
                    }
                    .p-datepicker-year{
                        padding: 6px 12px;
                    }
                }
            } 
            &-calendar-container{
                padding-inline: 8px;
                .p-datepicker-calendar{
                        th, td{
                            font-size: rem(14);
                            font-weight: 400;
                            text-align: center;
                            color: #5C7070;
                        }
                        td{
                            padding: rem(4) rem(8) !important;
                        }
                    tbody{
                        .p-datepicker-today{
                            span{
                                box-shadow: none;
                                border-color: variableValues($purple, '70');
                                color: variableValues($purple, '70');
                            }
                        }
                        .p-highlight{
                            background-color: #D5ECEC;
                            color: variableValues($teal, '70');
                        }
                    }
                }
            }  
        }
        .p-monthpicker{
            &-month{
                font-size: rem(14);
                font-weight: 400;
                text-align: center;
                color: #5C7070;
                &:hover{
                    background-color: #F4F6F6 !important;
                }
            }
        }
    }
    .rw__input-search-with-button{
        border: 1px solid variableValues($gray, "20");
        display: flex;
        align-items: center;
        @extend .rounded-5;
        button{
            min-width: rem(52);
            padding: rem(12) rem(15);
            @extend .h-100;
            background-color: variableValues($teal, "10") !important;
            &:hover, &:focus{
                @extend .text-primary;
                background-color: variableValues($teal, "10") !important;
                span{
                        transform: scale(1.1);
                }
            }
        }
        input{
            @extend .border-0;
        }
        &:focus-within {
            border-color: variableValues($teal, "60") !important;
        }
	}
    &-icon-close {
        .fa-xmark {
            @extend .d-block;
        }
    }
    .fa {
        background-size: contain !important;
        &-circle-xmark {
            @extend .d-none;
        }
    }
    .p-password-mask {
        .pi-eye,
        .pi-eye-slash {
            color: variableValues($gray, "70");
            position: absolute;
            right: rem(12);
            top: rem(16);
            margin-top: 0;
        }
    }
    .p-password-input {
        padding-inline-end: rem(52) !important;
    }
    
    .p-password-clear-icon {
        right: rem(32);
        left: auto;
        color: variableValues($gray, "50");
    }
    &-float {
        min-width: rem(280);
        @include mq('md', 'max') {
            min-width: auto
        }
        input {
            background-color: white;
            padding: rem(11) rem(11) rem(11) rem(15) !important;
            height: rem(48);
            width: 100%;
            @extend .rw__fs-8;
            &:focus {
                ~ label {
                    color: variableValues($teal, "50") !important;
                }
            }
        }
        .p-dropdown-label {
            padding: rem(11) rem(11) rem(11) rem(15) !important;
        }
        .p-multiselect {
            &-label {
                padding: rem(11) rem(11) rem(11) rem(15) !important;
                &-container {
                    padding: 0 !important;
                }
            }
        }
        textarea {
            background-color: white;
            padding: rem(12) rem(16) !important;
            height: rem(121);
            margin-bottom: rem(8);
            width: 100%;
            @extend .rw__fs-8;
            &:focus {
                ~ label {
                    color: variableValues($teal, "50") !important;
                }
            }
        }
        > label {
            top: rem(26);
            left: rem(16);
        }
        &.rw__input-icon {
            [class^="fa"] {
                @extend .position-absolute;
                &:not(.fa-chevron-down) {
                    top: rem(16);
                }
                &:not(.fa-xmark) {
                    @extend .rw__fs-8;
                }
                z-index: 1;
            }
            &-left {
                [class^="fa"]:not(.fa-xmark) {
                    left: rem(16);
                }
                > label {
                    left: rem(47);
                    i {
                        color: variableValues($red, "60") !important;
                    }
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-left: rem(47) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-left: 0 !important;
                }
            }
            &-right {
                [class^="fa"]:not(.fa-xmark) {
                    right: rem(12);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-right: rem(51) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-right: 0 !important;
                }
            }
            &-close {
                .fa-xmark {
                    background-color: variableValues($gray, "20");
                    @extend .rounded-circle;
                    @extend .rw__fs-10;
                    cursor: pointer;
                    padding: rem(6) rem(8);
                    right: rem(12);
                    height: rem(24);
                    width: rem(24);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-right: rem(51) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-right: 0 !important;
                }
            }
        }
        &-md {
            input {
                height: rem(36) !important;
                padding: rem(7) rem(7) rem(7) rem(10) !important;
            }
            .p-inputtext {
                font-size: rem(14) !important;
            }
            i {
                font-size: rem(14) !important;
                top: rem(9) !important;
            }
            .p-dropdown-label,
            .p-multiselect-label {
                height: rem(36) !important;
                padding: rem(7) 0 rem(7) rem(10) !important;
            }
            label {
                font-size: rem(14) !important;
                top: rem(21);
            }
            .p-inputwrapper-filled ~ label {
                font-size: rem(12) !important;   
            }
        }
        &-fixed {
            width: rem(280);
        }
        .p-calendar {
            input {
                height: rem(46);
            }
            &-w-btn {
                .p-datepicker-trigger {
                    font-size: rem(20) !important;
                    min-width: rem(46);
                }
            }
        }
    }
    &-default {
        min-width: rem(280);
        @include mq('md', 'max') {
            min-width: auto
        }
        input {
            background-color: white;
            padding: rem(11) rem(11) rem(11) rem(15) !important;
            height: rem(48);
            width: 100%;
            @extend .rw__fs-8;
        }
        .p-dropdown-label,
        .p-multiselect-label {
            padding: rem(11) rem(11) rem(11) rem(15) !important;
        }
        &.rw__input-icon {
            [class^="fa"] {
                @extend .position-absolute;
                &:not(.fa-chevron-down) {
                    top: rem(16);
                }
                &:not(.fa-xmark) {
                    @extend .rw__fs-8;
                }
                z-index: 1;
            }
            &-left {
                [class^="fa"]:not(.fa-xmark):not(.fa-chevron-down) {
                    left: rem(16);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-left: rem(47) !important;
                }
                input {
                    &:focus + label {
                        margin: 0;
                    }
                }
                label {
                    margin: rem(-8) 0 0 rem(26);
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-left: 0 !important;
                }
            }
            &-right {
                [class^="fa"]:not(.fa-xmark):not(.fa-chevron-down) {
                    right: rem(12);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-right: rem(51) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-right: 0 !important;
                }
            }
            &-close {
                .fa-xmark {
                    background-color: variableValues($gray, "20");
                    @extend .rounded-circle;
                    @extend .rw__fs-10;
                    cursor: pointer;
                    padding: rem(6) rem(8);
                    right: rem(12);
                    height: rem(24);
                    width: rem(24);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-right: rem(51) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-right: 0 !important;
                }
            }
        }
        .p-dropdown,
        .p-multiselect {
            .p-dropdown-trigger,
            .p-multiselect-trigger {
                [class^="fa"] {
                    @extend .rw__fs-8;
                }
            }
        }
        &-fixed {
            width: rem(280);
        }
        .p-calendar {
            input {
                height: rem(46);
            }
            &-w-btn {
                .p-datepicker-trigger {
                    font-size: rem(20) !important;
                    min-width: rem(46);
                }
            }
        }
        .p-inputmask {
            input {
                color: variableValues($gray, "70");
            }
            &-clear-icon {
                right: rem(15);
            }
        }
    }
    &-compact {
        min-width: rem(180);
        @include mq('md', 'max') {
            min-width: auto
        }
        input {
            background-color: white;
            padding: rem(7) rem(7) rem(7) rem(12) !important;
            height: rem(36);
            width: 100%;
            @extend .rw__fs-9;
        }
        .p-inputnumber{
            &-button-group{
                button{
                    height: rem(16);
                }
            }
        } 
        .p-dropdown {
            &-label {
                padding: rem(4) rem(5) rem(5) rem(12) !important;
                height: rem(34);
                @extend .d-flex;
                @extend .align-items-center;
                @extend .rw__fs-9;
            }
            &-trigger {
                width: rem(30);
                &-icon {
                    @extend .rw__fs-11;
                }
            }
            .p-dropdown-clear-icon {
                font-size: rem(12);
                top: rem(18);
                right: rem(36);
            }
        }
        .p-multiselect {
            &-label {
                padding: rem(7) rem(5) rem(5) rem(12) !important;
                height: rem(34);
                @extend .rw__fs-9;
            }
            &-trigger {
                width: rem(30);
                &-icon {
                    @extend .rw__fs-11;
                }
            }
        }
        > label {
            top: rem(5);
            @extend .position-absolute;
            @extend .start-0;
            @extend .rw__fs-10;
            @extend .rw__mb-0;
            + .fa {
                top: rem(32) !important;
            }
        }
        &.rw__input-icon {
            .fa-regular,
            .fa-solid,
            .fa-lg,
            [class^="fa"] {
                @extend .position-absolute;
                &:not(.fa-chevron-down) {
                    top: 50%;
                    transform: translateY(-50%);
                    color: #5C7070;
                }
                &:not(.fa-xmark) {
                    font-size: rem(16);
                }
                z-index: 1;
            }
            &-left {
                .fa-regular,
                .fa-solid,
                .fa-lg,
                [class^="fa"],
                .fa:not(.fa-xmark) {
                    left: rem(12);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-left: rem(39) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-left: 0 !important;
                }
            }
            &-right {
                .fa-regular,
                .fa-solid,
                .fa-lg,
                [class^="fa"]:not(.fa-xmark) {
                    right: rem(8);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-right: rem(43) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-right: 0 !important;
                }
            }
            &-close {
                .fa-xmark {
                    background-color: variableValues($gray, "20");
                    @extend .rounded-circle;
                    @extend .rw__fs-11;
                    cursor: pointer;
                    padding: rem(5) rem(6);
                    right: rem(8);
                    height: rem(22);
                    width: rem(22);
                }
                input,
                .p-dropdown,
                .p-multiselect {
                    padding-right: rem(43) !important;
                }
                .p-dropdown-label,
                .p-multiselect-label {
                    padding-right: 0 !important;
                }
            }
        }
        &-fixed {
            width: rem(180);
        }
        &-md {
            input {
                height: rem(32);
                padding: rem(7) rem(7) rem(7) rem(7) !important;
            }
            i {
                font-size: rem(14) !important;
                top: rem(9) !important;
            }
            .p-dropdown-label,
            .p-multiselect-label {
                height: rem(30);
                padding: rem(7) 0 rem(7) rem(5) !important;
            }
        }
        &-sm {
            input {
                height: rem(24);
                padding: rem(7) rem(7) rem(7) rem(7) !important;
            }
            .p-dropdown-label,
            .p-multiselect-label {
                height: rem(22);
                padding: rem(7) rem(5) rem(5) rem(5) !important;
            }
        }
        &.rw__input-suffix {
            .rw__input-suffix-addon {
                top: rem(7);
                right: rem(10);
            }
        }
        .p-calendar {
            input {
                height: rem(34);
            }
            &-w-btn {
                .p-datepicker-trigger {
                    font-size: rem(20) !important;
                    min-width: rem(36);
                    .pi-calendar {
                        background-image: none !important;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
    &-primary-light {
        .p-dropdown,
        .p-multiselect,
        .p-calendar {
            background-color: variableValues($green, "9");
            border-color: variableValues($green, "9");
            &-label {
                color: variableValues($teal, "80") !important;
            }
            &-trigger {
                &-icon {
                    color: variableValues($teal, "80") !important;
                }
            }
            .p-datepicker-trigger {
                background-color: transparent !important;
            }
            &:hover {
                border-color: variableValues($teal, "50");
            }
        }
        .p-inputnumber {
            &-buttons-stacked {
                .p-inputnumber-button-group {
                    button {
                        background-color: variableValues($teal, "20");
                        span {
                            color: variableValues($teal, "80") !important;
                        }
                    }
                }
            }
        }
    }
    &-suffix {
        input {
            padding-right: rem(54) !important;
        }
        &-addon {
            color: variableValues($gray, "50");
            position: absolute;
            top: rem(12);
            right: rem(16);
            @extend .rw__fs-8;
        }
    }
    &-prefix {
        input {
            padding-left: rem(60) !important;
        }
        &-addon {
            color: variableValues($gray, "50");
            position: absolute;
            top: rem(13);
            left: rem(16);
            @extend .rw__fs-8;
        }
    }
    &-custom-multi-select {
        .p-multiselect{
            background: transparent;
            @extend .border-0;
            .p-multiselect-label {
              background-image: url('../../assets/images/view_column.svg');
              background-repeat: no-repeat;
              background-position-y: rem(13);
              background-color: transparent;
              color: variableValues($gray, "80") !important;
              padding: rem(10) rem(10) rem(10) rem(30);
              @extend .fw-700;
              @extend .text-primary;
            }
            .p-multiselect-trigger{
              width: rem(12);
              .fa-chevron-down{
                font-size: rem(12);
              }
            }
            .p-multiselect-open {
              background-color: #f0fafa;
              .p-multiselect-label {
                  background-image: url('../../assets/images/view_column_green.svg');
                  color: variableValues($teal, "60") !important;
              }
              .p-multiselect-trigger {
                  color: variableValues($teal, "60");
              }
            }
        }
    }
    &-hide-number {
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
    &-icon-clickable {
        background-color: white;
        input {
            background-color: transparent;
            position: relative;
            z-index: 1;
        }
        label {
            z-index: 1;
        }
        i {
            z-index: 0 !important;
        }
    }
    .ng-touched.ng-invalid{
        border-color: variableValues($red, '60');
    }
    &-transparent {
        .p-dropdown {
            border: 0;
            &-label {
                font-weight: 500;
                padding: 0;
            }
            &-trigger {
                width: auto;
                &-icon {
                    font-size: rem(12);
                    margin-left: rem(8);
                }
            }
        }
    }
    &-checkbox {
        p-checkbox {
            margin-right: rem(5);
        }
        label {
            position: relative;
            top: rem(2);
        }
    }
    &-countdown {
        input {
            border: 0;
            text-align: center;
            width: rem(62);
            @include mq('sm', 'max') {
                max-width: none;
                width: 100%;
            }
        }
        .p-inputnumber {
            @include mq('sm', 'max') {
                width: 100%;
            }
            &-button {
                height: rem(48);
                width: rem(48);
            }
        }
    }

    & + p-selectbutton {
        display: block;
        margin-top: rem(8);
        .p-button {
            padding: rem(1) rem(8);
            margin-right: rem(8);
        }
    }
    .p-multiselect {
        &-token {
            @extend .rw__fs-10;
            @extend .fw-500;
            @extend .bg-grey-20;
            @extend .text-grey-80;
            padding: rem(2) rem(5) rem(2) rem(7) !important;
            &-icon {
                @extend .rw__fs-10;
            }
        }
    }
    &-responsive {
        @include mq('md','max'){
            height: 100%;
            .p-dropdown {
                height: 100%;
                &-trigger,
                span:not(.p-dropdown-label) {
                    display: none;
                }
                &-label {
                    span{
                        display: none;
                    }
                }
            }
            &.rw__input-compact-md.rw__input-icon-left {
                .p-dropdown {
                    padding-left: rem(30) !important;
                }
                i {
                    font-size: rem(11) !important;
                    left: rem(10) !important;
                    top: rem(10) !important;
                }
            }
        }
    }
}

.ltr {
    .p-inputgroup {
        .rw__input input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        .rw__button {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
    .p-inputnumber {
        &-buttons-stacked {
            .p-inputtext {
                border-right: 0;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
            .p-inputnumber-button-group {
                border-left: 0;
                border-top-right-radius: rem(5) !important;
                border-bottom-right-radius: rem(5) !important;
            }
        }
    }
}

.rtl {
    input,
    p-dropdown,
    .p-dropdown,
    p-multiselect,
    .p-multiselect,
    textarea {
        font-weight: 600;
    }
    .p-dropdown,
    .p-multiselect {
        &-label,
        &-label span {
            @extend .arabic-font;
            font-weight: 600;
        }
        &-token {
            span.p-multiselect-token-icon {
                font-family: variableValues($fonts, "pi") !important;
                margin: 0 rem(8) 0 0 !important;
            }
        }
    }
    .p-dropdown {
        &-header {
            .p-dropdown-filter {
                margin-right: 0;
                margin-left: rem(-24);
                padding-left: rem(15) !important;
                padding-right: rem(11);
            }
            .p-dropdown-filter-icon {
                right: auto;
                left: rem(12);
            }
        }
        &-clear-icon {
            right: auto !important;
            left: rem(36);
        }
    }
    .rw__input {
        &-float {
            input {
                padding: rem(11) rem(15) rem(11) rem(11) !important;
            }
            .p-password-input {
                padding-left: rem(52) !important;
            }
            .p-dropdown-label,
            .p-multiselect-label {
                padding: rem(11) rem(15) rem(11) rem(11) !important;
            }
            &.rw__input-icon {
                &-left {
                    > label {
                        right: rem(47);
                    }
                    input,
                    .p-dropdown,
                    .p-multiselect {
                        padding-left: rem(11) !important;
                        padding-right: rem(47) !important;
                    }
                    .p-dropdown-label,
                    .p-multiselect-label {
                        padding-right: 0 !important;
                    }
                }
                &-right {
                    input,
                    .p-dropdown,
                    .p-multiselect {
                        padding-left: rem(52) !important;
                        padding-right: rem(11) !important;
                    }
                    .p-dropdown-label,
                    .p-multiselect-label {
                        padding-left: 0 !important;
                    }
                }
                .p-password-clear-icon {
                    left: rem(32) !important;
                    right: auto;
                }
            }
        }
        &-compact {
            input {
                padding: rem(7) rem(12) rem(7) rem(7) !important;
            }
            .p-inputnumber{
                &-button-group{
                    button{
                        height: rem(16);
                    }
                }
            } 
            .p-dropdown-label,
            .p-multiselect-label {
                padding: rem(7) rem(12) rem(7) rem(7) !important;
            }
            &.rw__input-icon {
                &-left {
                    .fa-regular,
                    .fa-solid,
                    .fa-lg,
                    [class^="fa"],
                    .fa:not(.fa-xmark) {
                        right: rem(12);
                    }
                    input,
                    .p-dropdown,
                    .p-multiselect {
                        padding-left: rem(12) !important;
                        padding-right: rem(39) !important;
                    }
                    .p-dropdown-label,
                    .p-multiselect-label {
                        padding-right: 0 !important;
                    }
                }
                &-right {
                    .fa-regular,
                    .fa-solid,
                    .fa-lg,
                    [class^="fa"]:not(.fa-xmark) {
                        left: rem(8);
                    }
                    input,
                    .p-dropdown,
                    .p-multiselect {
                        padding-left: rem(43) !important;
                        padding-right: rem(12) !important;
                    }
                    .p-dropdown-label,
                    .p-multiselect-label {
                        padding-left: 0 !important;
                    }
                }
            }
        }
        &-default {
            input {
                padding: rem(11) rem(15) rem(11) rem(11) !important;
            }
            &.rw__input-icon {
                &-close {
                    input,
                    .p-dropdown,
                    .p-multiselect {
                        padding-left: rem(51) !important;
                        padding-right: 0 !important;
                    }
                    .p-dropdown-label,
                    .p-multiselect-label {
                        padding-left: 0 !important;
                    }
                }
                &-left {
                    input,
                    .p-dropdown,
                    .p-multiselect {
                        padding-left: 0 !important;
                        padding-right: rem(47) !important;
                    }
                    .p-dropdown-label,
                    .p-multiselect-label {
                        padding-right: 0 !important;
                    }
                }
                &-right {
                    input,
                    .p-dropdown,
                    .p-multiselect {
                        padding-left: rem(47) !important;
                        padding-right: 0 !important;
                    }
                    .p-dropdown-label,
                    .p-multiselect-label {
                        padding-left: 0 !important;
                    }
                }
            }
            .p-inputmask {
                &-clear-icon {
                    left: rem(15);
                    right: auto;
                }
            }
        }
        &-icon {
            &-close {
                .fa-xmark {
                    left: rem(12);
                    right: auto;
                }
            }
            &-left {
                [class^="fa"]:not(.fa-xmark):not(.fa-chevron-down) {
                    left: auto;
                    right: rem(16);
                }
            }
            &-right {
                [class^="fa"]:not(.fa-xmark):not(.fa-chevron-down) {
                    right: auto;
                    left: rem(12);
                }
            }
        }
        &-custom-multi-select {
            .p-multiselect{
                .p-multiselect-label {
                  padding: rem(10) rem(30) rem(10) rem(10) !important;
                  background-position-x: 100%;
                }
            }
        }
        &-suffix {
            input {
                padding-left: rem(54) !important;
                padding-right: rem(15) !important;
            }
            &-addon {
                right: auto;
                left: rem(16);
            }
        }
        &-prefix {
            input {
                padding-left: rem(15) !important;
                padding-right: rem(60) !important;
            }
            &-addon {
                left: auto;
                right: rem(16);
                top: rem(12);
            }
        }
        &-checkbox {
            p-checkbox {
                margin-left: rem(5);
                margin-right: 0;
            }
        }
    }
    .p-inputgroup {
        .rw__input input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
        .rw__button {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    .p-calendar {
        &-clearable {
            .p-calendar-clear-icon {
                left: rem(14);
                right: auto !important;
            }
        }
        &-w-btn {
            .p-calendar-clear-icon {
                left: rem(60);
                right: auto !important;
            }
            .p-datepicker-trigger {
                border-top-left-radius: rem(4);
                border-bottom-left-radius: rem(4);
            }
        }
    }
    .p-inputnumber {
        &-buttons-stacked {
            .p-inputtext {
                border-left: 0;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
            .p-inputnumber-button-group {
                border-right: 0;
                border-top-left-radius: rem(5) !important;
                border-bottom-left-radius: rem(5) !important;
                .p-inputnumber-button {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    &:first-child{
                        border-top-left-radius: 4px !important;
                    }
                    &:last-child{
                        border-top-left-radius: 0 !important;
                    }
                }
            }
        }
    }
    .p-password-mask {
        .pi-eye,
        .pi-eye-slash {
            right: auto;
            left: rem(12);
        }
    }
}

.rw__dropdown-primary-list {
    li.p-highlight {
        background-color: variableValues($teal, "20") !important;
    }
}

.p-overlay{
    &-content{
        .p-dropdown{
            &-panel{
                border-radius: rem(4);
                border: rem(1) solid variableValues($gray, '22');
                background-color: #fff;
                box-shadow: 0 rem(4) rem(6) rgba(black, 0.10);
            }
        }
    }
}

// TODO:UI:FIX temporary fix until Bootstrap remove

.grid > .col, .grid > [class*=col],
.form-row > .col, .form-row > [class*=col-] {
    position: relative;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    .form-row>.col, .form-row>[class*=col-] {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.rw-input-sku {
    position: relative;
    rw-input {
        width: 100%;
        display: block;
        .p-float-label {
            input {
                padding-right: 60px;
            }
            .reset-button {
                right: 60px;
            }
        }
    }
    rw-sku-generator {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        z-index: 1;
        border: 1px solid transparent;
        .p-button {
            border: 0 !important;
            padding: 0;
            min-width: 0;
            width: 50px;
            height: 50px;
            background-color: variableValues($teal, "10");
            &:enabled:hover, &:enabled:focus, &:enabled:active {
                background-color: variableValues($teal, "20") !important;
            }
        }
    }
}



.rtl{
    .rw-input-sku {
        rw-input {
            .p-float-label {
                input {
                    padding-left: 60px;
                    padding-right: rem(12)
                }
                .reset-button {
                    left: 60px !important;
                    right: auto;

                }
            }
        }
        rw-sku-generator {
            left: 0;  
            right: auto;
        }
    }
}