// Tags
$tags: (
    "purple" variableValues($purple, "10") variableValues($purple, "70") variableValues($purple, "30"),
    "teal" variableValues($teal, "20") variableValues($teal, "70") variableValues($teal, "30"),
    "gray" variableValues($gray, "20") variableValues($gray, "70") variableValues($gray, "30"),
    "green" variableValues($green, "20") variableValues($green, "70") variableValues($green, "30"),
    "yellow" variableValues($yellow, "20") variableValues($yellow, "70") variableValues($yellow, "30"),
    "red" variableValues($red, "20") variableValues($red, "70") variableValues($red, "30"),
    "blue" variableValues($blue, "20") variableValues($blue, "70") variableValues($blue, "30"),
);

// Generate Tags Class
@each $name, $background, $text, $hover in $tags {
    .rw__tag-#{$name} {
      background-color: $background;
      color: $text;
      &:not(.rw__tag-no-hover):hover {
        background-color: $hover;
      }
    }
}

// Status
$status: (
    "purple" variableValues($purple, "10") variableValues($purple, "70"),
    "teal" variableValues($teal, "10") variableValues($teal, "70"),
    "gray" variableValues($gray, "10") variableValues($gray, "70"),
    "green" variableValues($green, "10") variableValues($green, "70"),
    "success" variableValues($green, "10") variableValues($green, "70"),
    "yellow" variableValues($yellow, "10") variableValues($yellow, "70"),
    "warning" variableValues($yellow, "10") variableValues($yellow, "70"),
    "red" variableValues($red, "10") variableValues($red, "70"),
    "danger" variableValues($red, "10") variableValues($red, "70"),
    "error" variableValues($red, "10") variableValues($red, "70"),
    "blue" variableValues($blue, "10") variableValues($blue, "70"),
    "info" variableValues($blue, "10") variableValues($blue, "70"),
    "expired" variableValues($gray, "21") variableValues($gray, "60"),
);

// Generate Tags Class
@each $name, $background, $text in $status {
    .rw__status-#{$name} {
      background-color: $background;
      color: $text;
    }
}
