// Colors
$primaryColors: (
    "primary": variableValues($teal, "60"),
    "danger": variableValues($red, "60"),
    "warning": variableValues($yellow, "60"),
    "success": variableValues($green, "60"),
    "info": variableValues($blue, "60"),
    "disabled": variableValues($gray, "30"),
    "white": white,
    "transparent": transparent,
    "grey-7": variableValues($gray, "7"),
    "grey-8": variableValues($gray, "8"),
    "grey-9": variableValues($gray, "9"),
    "grey-10": variableValues($gray, "10"),
    "grey-20": variableValues($gray, "20"),
    "grey-21": variableValues($gray, "21"),
    "grey-22": variableValues($gray, "22"),
    "grey-30": variableValues($gray, "30"),
    "grey-40": variableValues($gray, "40"),
    "grey-50": variableValues($gray, "50"),
    "grey-60": variableValues($gray, "60"),
    "grey-70": variableValues($gray, "70"),
    "grey-80": variableValues($gray, "80"),
    "teal-10": variableValues($teal, "10"),
    "teal-20": variableValues($teal, "20"),
    "teal-50": variableValues($teal, "50"),
    "teal-60": variableValues($teal, "60"),
    "teal-90": variableValues($teal, "90"),
    "purple-10": variableValues($purple, "10"),
    "purple-60": variableValues($purple, "60"),
    "purple-70": variableValues($purple, "70"),
    "blue-60": variableValues($blue, "60"),
    "green-9": variableValues($green, "9"),
    "green-10": variableValues($green, "10"),
    "green-20": variableValues($green, "20"),
    "green-30": variableValues($green, "30"),
    "green-40": variableValues($green, "40"),
    "green-50": variableValues($green, "50"),
    "green-60": variableValues($green, "60"),
    "danger-10": variableValues($red, "10"),
    "danger-50": variableValues($red, "50"),
    "yellow-10": variableValues($yellow, "10"),
    "yellow-20": variableValues($yellow, "20"),
    "yellow-30": variableValues($yellow, "30"),
    "yellow-40": variableValues($yellow, "40"),
    "yellow-50": variableValues($yellow, "50"),
    "yellow-60": variableValues($yellow, "60"),
    "yellow-70": variableValues($yellow, "70"),
    "yellow-80": variableValues($yellow, "80"),
    "red-10":  variableValues($red, "10"),
    "red-20":  variableValues($red, "20"),
    "red-30":  variableValues($red, "30"),
    "red-40":  variableValues($red, "40"),
    "red-50":  variableValues($red, "50"),
    "red-60":  variableValues($red, "60"),
    "red-70":  variableValues($red, "70"),
    "red-80":  variableValues($red, "80"),
);

// Generate Headings Class
@each $name, $value in $primaryColors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .text-#{$name} {
      color: #{$value} !important;
    }
    .border-#{$name} {
        border-color: #{$value} !important;
    }
    .border-start-#{$name} {
        border-left-color: #{$value} !important;
    }
    .border-end-#{$name} {
        border-right-color: #{$value} !important;
    }
}

.nb__anchor-primary {
    color: variableValues($teal, "60") !important;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
