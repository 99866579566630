.rw__dialog {
    .p-dialog {
        width: 100%;
        @include mq('md', 'max') {
            margin: 0 !important;
        }
        &-header {
            align-items: flex-start;
            padding: rem(32) rem(32) rem(24);
            border-top-left-radius: rem(4);
            border-top-right-radius: rem(4);
            @include mq('md', 'max') {
                border-radius: 0 !important;
                padding: rem(16) rem(16) rem(24);
            }
            &-close {
                height: rem(24) !important;
                width: rem(24) !important;
                &-icon {
                    @extend .rw__fs-10;
                }
            }
            label {
                line-height: rem(28);
            }
        }
        &-title {
            color: variableValues($gray, "80");
            @extend .rw__fs-6;
            @extend .fw-500;
        }
        &-content {
            @extend .d-flex;
            @extend .flex-column;
            padding: rem(8) rem(32) 0;
            @include mq('md', 'max') {
                padding: rem(16) rem(16) 0;
            }
            &:first-of-type {
                border-top-left-radius: rem(4);
                border-top-right-radius: rem(4);
            }
            &:last-of-type {
                border-bottom-left-radius: rem(4);
                border-bottom-right-radius: rem(4);
            }
        }
        &-footer {
            border-bottom-left-radius: rem(4);
            border-bottom-right-radius: rem(4);
            padding: rem(32);
            @include mq('md', 'max') {
                border-radius: 0 !important;
                padding: rem(16) rem(16);
            }
            button {
                margin: 0;
                &:not(:first-child) {
                    margin-inline-start: rem(16);
                }
            }
            &-gap-0 {
                margin: 0 rem(-32);
            }
        }
    }
    &-disable-overlay {
        .p-component-overlay {
            background-color: transparent !important;
            pointer-events: none !important;
        }
    }
    .rw__datatable {
        p-table {
            overflow: auto;
            .p-datatable-wrapper {
                overflow: initial;
            }
        }
    }
    &-xl {
        .p-dialog {
            max-width: rem(1246);
        }
    }
    &-lg {
        .p-dialog {
            max-width: rem(960);
        }
    }
    &-md {
        .p-dialog {
            max-width: rem(640);
        }
    }
    &-sm {
        .p-dialog {
            max-width: rem(480);
        }
    }
    &-no-footer {
        .p-dialog-content {
            padding-bottom: rem(32);
        }
    }
    &-empty-header{
        .p-dialog-header {
            padding-bottom: rem(0) !important;
        }
    }
    &-content-internal {
        margin: 0 rem(-32);
    }
    &:not(.rw__dialog-no-responsive) {
        .p-dialog {
            @include mq('md', 'max') {
                max-height: 100%;
                height: 100vh;
                max-width: none;
                width: 100vw !important;
            }
        }
    }
    &-responsive-min-height:not(.rw__dialog-no-responsive) {
        .p-dialog {
            @include mq('md', 'max') {
                max-height: 100%;
                height: auto;
                max-width: none;
                width: 100vw !important;
            }
        }
    }
    &.rw__dialog-responsive-bottom-fixed{
        .p-dialog {
            @media screen and (max-width: 500px) {
                width: 100%;
                position: fixed !important;
                bottom: 0;
                top: auto !important;
                max-height: 95%;
                animation: boxHeightAuto 1s;
                -webkit-animation: boxHeightAuto 1s;
                animation-fill-mode: forwards;
                -webkit-animation-fill-mode: forwards;
                &-header {
                    border-top-right-radius: rem(16) !important;
                    border-top-left-radius: rem(16) !important;
                    &:before {
                    content: '';
                    position: absolute;
                    top: rem(10);
                    left: 50%;
                    transform: translateX(-50%);
                    width: rem(65);
                    border-radius: rem(5);
                    height: rem(5);
                    background-color: variableValues($gray, '50');
                    }
                }
            }
          }
    }
}

.trail .rw__dialog {
    .p-dialog {
        @include mq('md', 'max') {
            padding-top: rem(60) !important;
        }
    }
}
.rw__dynamic-dialog {
    &-sm {
        &.p-dynamic-dialog {
            max-width: rem(480);
        }
    }
    &-md {
        &.p-dynamic-dialog {
            max-width: rem(640);
        }
    }
    &-lg {
        &.p-dynamic-dialog {
            max-width: rem(960);
        }
    }
    &-xl {
        &.p-dynamic-dialog {
            max-width: rem(1246);
        }
    }
    &-no-footer {
        .p-dialog-content {
            padding-bottom: rem(32);
        }
    }
    &.p-dynamic-dialog {
        width: 100%;
        @include mq('md', 'max') {
            max-height: 100%;
            height: 100vh;
            width: 100vw !important;
        }
    }
    &-no-header {
        .p-dialog-content {
            border-radius: rem(4);
        }
    }
    &-content-no-space {
        .p-dialog-content {
            padding: 0 !important;
        }
    }
    .p-dialog {
        &-header {
            align-items: flex-start;
            padding: rem(32) rem(32) rem(24);
            @include mq('md', 'max') {
                border-radius: 0 !important;
            }
            &-close {
                height: rem(24) !important;
                width: rem(24) !important;
                &-icon {
                    @extend .rw__fs-9;
                }
            }
            label {
                line-height: rem(28);
            }
        }
        &-title {
            color: variableValues($gray, "80");
            @extend .rw__fs-6;
            @extend .fw-500;
        }
        &-content {
            @extend .d-flex;
            @extend .flex-column;
            padding: rem(8) rem(32) 0;
        }
        &-content-wrap{
            flex-grow: 1;
        }
        &-footer {
            padding: rem(32) 0;
            @include mq('md', 'max') {
                border-radius: 0 !important;
            }
            button {
                margin: 0;
                &:not(:first-child) {
                    @extend .rw__ms-5;
                }
            }
        }
    }
    .rw__datatable {
        p-table {
            overflow: auto;
            .p-datatable-wrapper {
                overflow: initial;
            }
        }
    }
}

@keyframes boxHeight {
    from {
      height: 0;
    }
    to {
      height: 95%;
    }
  }
  
  @-webkit-keyframes boxHeight {
    from {
      height: 0;
    }
    to {
      height: 95%;
    }
  }
  @keyframes boxHeightAuto {
    from {
      height: 0;
    }
    to {
      height: auto;
    }
  }
  
  @-webkit-keyframes boxHeightAuto {
    from {
      height: 0;
    }
    to {
      height: auto;
    }
  }
  