@import "styles/index";


.english-font {
  :not(span):not(i):not(.pi-times:before):not(.fa-chevron-right:before) {
    font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif'
  };
}

.arabic-font {
  :not(span):not(i):not(.pi-chevron-right):not(.fa-circle-info):not([class*="fa-"]), .p-placeholder {
    font-family: 'Cairo', sans-serif !important;
  }
}


body {
  margin: 0;
}

html,
body {
  height: 100%;
  page-break-after: avoid;
  page-break-before: avoid;
  margin: 0;
  background-color: #f6f8fa;
}

#ug-tooltip-frame { margin-top: -20px !important;}

.rtl {
  .dropdown-menu {
    text-align: start;
    left: auto !important;
    margin-left: 15px;
  }

  .mdb-select-toggle {
    left: 0;
    right: auto;
  }

  .select-dropdown li {
    text-align: right;
  }

/* calendar */
.p-calendar-w-btn .p-inputtext {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.p-calendar-w-btn .p-datepicker-trigger {
  border-bottom-right-radius: 4px!important;
  border-top-right-radius: 4px!important;
}
.pi-chevron-right:before {
  content: "\e900";
}
.pi-chevron-left:before {
  content: "\e901";
}
.po-table-sub-headers{
  text-align: right;
}
.po-table-headers{
  text-align: right;
}
}

.cursor-pointer {
  cursor: pointer;
}

datatable-scroller {
  width: 100% !important;
}

.app-import {
  .progress-bar.bg-info {
    background-color: #00aaa8 !important;
  }
}

.stock-location {
  .datatable-footer {
    display: none !important;
  }
}

label.custom-control-label {
  position: relative;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

label.custom-control-label::before,
label.custom-control-label::after {
  right: 0;
  left: 0;
}

.underlined-text {
  text-decoration: underline;
}

.black-text {
  color: black !important;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #00b6b4 !important;
  height: 26px;
  width: 26px;
  padding-top: 2px;
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-right,
.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-prev {
  font-size: 18px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 7%);
  border: solid 0.5px #e0e0e0;
  background-color: #ffffff;
  padding: 4px 2.8px 0 2.7px;
}

[class^='datatable-icon-']:before,
[class*=' datatable-icon-']:before {
  line-height: initial;
}

.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgb(0, 0, 0);
  background-color: #fff;
}

.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  background: #fafafa;
  box-shadow: 0 0 0 0 !important;
}

.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 50% !important;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: #f5fbfb;
}

.pointer {
  cursor: pointer;
}

.custom-modal-en {
  margin-left: 33%;
  margin-top: 4%;
}

.custom-modal-ar {
  margin-left: -30%;
  margin-top: 4%;
}

.add-modal {
  margin-left: -18%;
  margin-top: 4%;
}

.register-num {
  max-width: 350px;
  margin-left: 50%;
}

.modal-content {
  margin-top: 50px;
}

@media screen and (max-width: 800px) {
  .custom-modal-en {
    margin-left: 0;
    margin-top: 0;
  }

  .custom-modal-ar {
    margin-left: 0;
    margin-top: 0;
  }

  .add-modal {
    margin-left: 0;
    margin-top: 0;
  }

  .register-num {
    margin-left: 20%;
  }
}

@media screen and (max-width: 700px) {
  .product-details-modal.p-dialog {
    width: 70% !important;
  }
}

@media screen and (max-width: 600px) {
  .product-details-modal.p-dialog {
    width: 80% !important;
  }
}

@media screen and (max-width: 500px) {
  .ngx-datatable .datatable-footer .datatable-footer-inner {
    display: grid !important;
  }

  .product-details-modal.p-dialog {
    width: 90% !important;
  }
}

@media screen and (max-width: 400px) {
  .register-num {
    margin-left: 0;
  }
}

.permission-disable {
  cursor: not-allowed;
  pointer-events: none;

  .ac-input {
    opacity: 0.38;
   }
  button {
    display: none !important;
  }
}

.monserrat-font {
  font-family: 'Montserrat', sans-serif;
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell:first-child {
  padding-top: 5px;

  p.headAlign.headDesign {
    padding-top: 5px;
  }
}

.custom-tooltip{
  background-color: #8d508c;
  color: #ffffff;
  font-size: 15px;
}

.multi-select-box{
  min-width: 180px;
  max-width: 270px;
  margin-top: -10px;
  width: 100%;
}

.number__dialog{
  z-index: 1000000;
}

.p-datepicker {
  z-index: 1000000 !important
}

.limited-width-datepicker {
  @media(min-width: 768px){
    min-width: 635px !important;
  }
}

// Flex 1
.flex-1 {
  flex: 1 1 0% !important;
  margin: 0.5rem !important;
}

.flex-2 {
  flex: 2 1 0% !important;
  margin: 0.5rem !important;
}

.flex-auto {
  flex: 1 1 auto !important;
  margin: 0.5rem !important;
}

//for table caption

.action-logs-list {
  b {
    font-weight: 500 !important;
  }
}

.top-z-index {
  z-index: 9999 !important
}

//table tooltip
.tooltip-cell{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.cash-list-fix-height{
  & .ngb-dp-day.hidden{
    visibility: hidden;
    display: block !important;
  }
}
rw-order-view .c-rw-datatable {
  overflow-x: auto !important;
  ngx-datatable {
    min-width: 1120px !important;
  }
  datatable-header {
    height: 60px !important;
  }
  datatable-footer {
    display: none !important;
  }
  datatable-body {
    overflow-x: hidden !important;

  }
}
rw-order-view  .ngx-datatable.order-view-tableData {
  border-bottom: 1px solid rgb(255, 255, 255) !important;
}

.modal-content-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background: none !important;
  background-clip: unset;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
}

.order-view {
  $width: auto !important;
  .ngx-datatable {
    .datatable-header {
      .datatable-header-cell:first-child {
        width: $width;
      }
    }
    .datatable-body {
      .datatable-body-cell:first-child {
        width: $width;
      }
    }
  }
}
.order-view-tableData {
  .p-datatable .p-datatable-tbody > tr > td {
    word-wrap: break-word;
  }
  .save-button{
    padding: 5px !important;
  }
}
.view-more-button {
  padding: 10px 20px 10px 20px;
  border: 1px solid #dce0e0 !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  color: #00807d;
}

.disabledQuotePnl.p-overlaypanel {
  background-color: #0d2222 !important;
  color: #fff !important;
}

.disabledQuotePnl.p-overlaypanel-flipped::before,
.disabledQuotePnl.p-overlaypanel-flipped::after {
  border-top-color: #0d2222 !important;
}
.rtl{
    .pos-pextra-modal-container{
    .variable-box{
      &-item{
        margin-left: 2.6%;
      }
    }
  }
}
.ltr{
  .pos-pextra-modal-container{
    .variable-box{
      &-item{
        margin-right: 2.6%;
      }
    }
  }
}
.pos-pextra-container{
  background-color:#fafafa;
  // height: 100vh;
}
.pos-pextra-modal{
  padding: 0px !important;
  // background-color: #fafafa !important;
  max-height: 100% !important;
  border-radius: 8px;
  background-color:#fafafa;
  // height: 100vh;
  // ::ng-deep{
    span{
      word-break: break-word;
    }
    .pos-pextra-modal-container{
      background-color:#fafafa;
      // margin: 10px;
      .pextra-header {
        padding: 16px;
        display: flex;
        justify-content: space-between;
      }

      .pextra-header-content{
        display: flex;
        align-items: center;
        padding: 8px;
        @media (max-width:500px) {
          flex-direction: column;
          align-items: flex-start;
        }
        span{
          font-size: 24px;
          font-weight: 500;
          line-height: 1.5;
          color: #0d2222;
          // margin-right: 49px;
        }
        .pextra-cart-quantity{
          display: flex;
          align-items: center;
          justify-content: space-between;
          input{
            border: none;
            background-color: transparent !important;
            font-size: 14px;
            line-height: 2.14;
            color: #0d2222;
            width: 3rem;
						text-align: center;
          }
        }
        .cart-quantity-button {
          color: black;
          cursor: pointer;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-color: #dce0e0;
      }
      }
      .btn-trash{
        padding: 0;
      }
      .close-button{
        position: absolute;
        top: -43px;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 0;
        height: auto;
        i{
          background-color: #edf0f0;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 13px 15px;
        }
        }
        .pextra-detail{
          padding: 16px;
        }
        .pextra-detail-pb{
          @media(max-width:768px){
            padding-bottom: 125px !important;
          }
        }
        .variable-box{
          padding: 0 8px;
          margin-bottom: 35px;
          &:last-child{
            margin-bottom: 0;
          }
          &-text{
            font-size: 14px;
            font-weight: 500;
            color: #0d2222;
            margin-bottom: 8px;
          }
          &-list{
            display: flex;
            flex-wrap: wrap;
          }
          &-item{
            flex: 0 0 21.6%;
            min-height: 84px;
            border-radius: 4px;
            box-shadow: 0 0 3px 0 #dce0e0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
             border: none;
             margin-bottom: 16px;
             @media only screen and (max-width:1024px){
              flex: 0 0 30.6%;
              min-height: 97px;
              // min-height: 125px;
             }
             @media only screen and (max-width:768px){
              flex: 0 0 47.3%;
              // min-height: 100px;
             }
            p{
              font-size: 14px;
              font-weight: 500;
              color: #0d2222;
            }
          }
          &--green, button:is(:hover,:active,:focus){
            border:1px solid #00a5a3;
            outline: none;
          }
          &--extra{
            .variable-box-item{
              min-height: 116px;

               @media only screen and (max-width:1024px){
               min-height: 140px;
               }
            }
          }
        }
        .extra-opt{
          &-wrap{
            border-radius: 4px;
          box-shadow: 0 0 3px 0 #dce0e0;
          background-color: #fff;
          padding: 24px;
          @media(max-width:992px){
            padding: 16px;
          }
          }
          &-text{
            font-size: 20px;
            line-height: 1.8;
            font-weight: 500;
            &+span{
              font-size: 16px;
              font-weight: 500;
              color: #0d2222;
              line-height: 2.25;
              display: flex;
              justify-content: center;
              align-items: center;
              small{
                font-size: 12px;
              }
            }
          }
          &-subtext{
            font-size: 14px;
            color: #5b6565;
          }
          &-total{
            border-top: 1px solid #dce0e0;
            display: flex;
            align-items: center;
            padding-top: 24px;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.25;
            sub{
              color: #313d3d;
            }
          }
          &-list{
            overflow: auto;
            // padding-left: 0;
          }
          &-item{
            background-color: #fafafa;
            display: flex;
            align-items: center;
          }
        }
        .add-to-cart-btn {
          height: 48px;
          padding: 0px !important;
          color: #fff;
          font-size: 16px;
          line-height: 1;
          border: 1px solid transparent;
          border-radius: 0.25rem;
        }
        .add-to-cart-btn:disabled {
          opacity: 0.65;
        }
      .pack-panel{
        .p-panel{
          box-shadow: none;
        }
        .p-panel-content{
          padding: 0!important;
        }
      }
     }

  // }
}
.rtl{
  .pos-pextra-modal{
      .pos-pextra-modal-container{
        .pextra-header-content{

          span{
            @media (min-width:500px) {
              margin-left: 49px;
            }
          }
        }
        .btn-trash{
          padding-right: 5px;
        }
        .close-button{
          left: 0;
          }

          .extra-opt{
            &-list{ //
              padding-right: 0;
            }
            &-text{
              &+span{
                small{
                  margin-left: 3px;
                }
              }
            }

          }
       }
  }
}
.ltr{
  .pos-pextra-modal{
    .pos-pextra-modal-container{
      .pextra-header-content{

        span{
          @media (min-width:500px) {
            margin-right: 49px;
          }
          // margin-: 49px;
        }
      }
      .btn-trash{
        padding-left: 5px;
      }
      .close-button{
        right: 0;
        }

        .extra-opt{
          &-list{ //
            padding-left: 0;
          }
          &-text{
            &+span{
              small{
                margin-left: 3px;
              }
            }
          }

        }
     }
}
}

.pos-payment-modal{
  border-radius: 8px;
  overflow: hidden;
  .p-dialog{
    &-content{
      padding: 0;
    }
  }
}
.pos-number-modal{
  width: 300px !important;
  @media(max-width: 500px){
    width: auto !important;
  }
.p-dialog-header {
  padding: 12px;
}
}
.feature-flag-disable {
  display: none !important;
}

.feature-flag-invisible {
  visibility: hidden !important;
}
.po-card-oder-details {
  width: auto;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.headingText{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.mt-24{
  margin-top: 24px;
}

.changeBorder{
border: 1px solid #ced4da !important;
}

.po-change-table-header{
  .p-datatable .p-datatable-thead > tr > th {
    border-radius: 4px !important;
    border: solid 0px #dfe1e5 !important;
    background-color: #fafafa !important;
    border-bottom: solid 1px #dfe1e5 !important;
    border-top: solid 1px #dfe1e5 !important;
  }
  .p-datatable table{
    border: 1px solid #dfe1e5 !important;
  }
}

//styling for transfer status
.transfer-status {
  padding: 0.5rem 0.75rem;
  border-radius: 16px;
  font-weight: 600 !important;
  &.Active,
  &.Accepted {
    color: #006636;
    background-color: #ebffef;
  }
  &.Scheduled,
  &.Draft,
  &.Waiting\Receive
  &.Saved {
    color: #5b6565;
    background-color: #edf0f0;
  }
  &.Pending {
    color: #007ace;
    background-color: #ebf2ff;
  }
  &.Ended,
  &.Expired,
  &.Rejected {
    color: #ba1b1b;
    background-color: #ffede9;
  }
  &.PartiallyAccepted {
    color: #996b00;
    background-color: #fff5e0;
  }
  &.Requested {
    color: #561157;
    background-color: #faedf7;
  }
}

.rtl .p-paginator-first .pi-angle-double-left::before {
  content: "\e92e";
}

.rtl .p-paginator-last .pi-angle-double-right::before {
  content: "\e92d";
}

.rtl .p-paginator-prev .pi-angle-left::before {
  content: "\e932";
}

.rtl .p-paginator-next .pi-angle-right::before {
  content: "\e931";
}

select.form-control {
  cursor: pointer;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background-color: #ffffff;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight).p-selectable-row:hover,
.p-datatable .p-datatable-tbody > tr.p-selectable-row:hover {
  cursor: pointer;
  background-color: #F0FAFA;
}

.dropdown-item {
  cursor: pointer;
}

.drop-list.dropdown{
  cursor: pointer;
}

.od-product-note {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #5b6565;
}

.po-product-name {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: inherit;
  color: #0d2222;
}
.po-product-number {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #738080;
}
.po-product-stock {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: hsl(180, 5%, 48%);
}
.po-amount-header{
  padding: 16px 32px 16px 16px;
border-radius: 5px;
border: solid 1px #dfe1e5;
background-color: #fff;
border-bottom-left-radius: 0px;
border-bottom-right-radius: 0px;
&.off-white-color{background-color: #fafafa;}
}
.po-table-sub-headers {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d2222;
}

.po-table-headers {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d2222;
}

.pos-dialog.p-dialog {
    background-color: white;
    border-radius: 10px;
    padding: 1rem 0;

    .p-dialog-content {
      padding: 0 1.25rem !important;
    }
}

.printer-plugin.p-dialog {
  width: 570px;
  max-height: 280px;
}


.product-details-modal.p-dialog {
  width: 60%;
  max-height: 95%;
}

.add-quick-menu-products-modal.p-dialog {
  width: 80%;
  max-height: 95%;
}

.ltr {
  .assign-printer-templates {
    .p-panel .p-panel-icons-end {
      order: unset;
    }
    .p-panel-header-icon {
      margin-left: 0 !important;
      margin-right: 23px;
    }
  }
}

.rtl {
  .assign-printer-templates {
    .p-panel .p-panel-icons-end {
      order: unset;
    }
    .p-panel-header-icon {
      margin-left: 23px !important;
      margin-right: 0;
    }
  }
}

.assign-printer-templates.p-dialog {
  width: 570px;
  max-height: 550px;
}

.pos-dialog.pos-confirm-dialog{
  border-radius: 4px !important;
  background-color: #fff;
  padding: 1rem !important;
  width: 480px;
  margin: 0.5rem;
  @media screen and (max-width:768px){
  width: auto;
  }
  .p-dialog-content{
    padding: 0 !important;
  }
}

.ngx-file-drop__content {
  display: block !important;
  color: #828282 !important;
  text-align: center !important;
}

.ngx-file-drop__drop-zone {
  border: none !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  height: 200px !important;
}

.date {
  direction: ltr;
}

span.date {
  display: inline-block;
}


.print-barcode-revamp {
  p{
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  label{
    margin-bottom: 0rem;
  }
}

.rtl{
  .p-multiselect .p-multiselect-clear-icon {
    left: 2.35rem;
    right: auto !important;
  }

  .p-multiselect-filter-container .p-multiselect-filter-icon{
    left: 0.75rem;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .print-barcode-revamp{
    .p-dropdown .p-dropdown-clear-icon{
      right: auto;
      left: 2.75rem;
    }

    .p-dropdown-clear-icon.pi.pi-times::before {
        font-size: 1rem;
    }

    .p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down{
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 0;
    }
    .p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up{
      border-top-right-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-inputnumber-buttons-stacked .p-inputnumber-input {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    p{
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
 }
}

.pos-setting-template-form.p-dialog {
  width: 500px;
}

.invoice-result-dialog {
  width: 624px;
}

.pos-setting-template-form .p-dialog-content {
  overflow-y: unset;
}

.pos-setting-template-form {
  .category-multi-select {
    top: 50px !important;
    .p-multiselect-items-wrapper {
      max-height: 200px !important;
    }
  }
}

.classic-prod-list{
  .ltr{
    .btn-show-prod{
        @media(min-width:1025px){
            margin-left: 56px;
        }
      }
  }
  .rtl{
    .btn-show-prod{
        @media(min-width:1025px){
            margin-right: 56px;
        }
      }
  }
}
.new-cart-total-compact{
  .ltr{
    .cart-product-image {
        @media(max-width:1024px){
            padding-right: 7px !important;
        }
      }
  }
  .rtl{
    .cart-product-image {
      @media(max-width:1024px){
          padding-left: 7px !important;
      }
    }
  }

}
.flex-item-custom-btn {

  .p-multiselect {
      border: none;

      .p-multiselect-trigger {
          width: 1rem;
          margin-right: 1rem;
      }
  }

  .p-multiselect-panel {
      width: 200px;
      border-radius: 6px;
      top: 60px !important;
  }

  .p-multiselect-label {
      background-image: url(assets/images/view_column.svg);
      background-repeat: no-repeat;
      background-position-y: 16px;
      background-position-x: 10px;
      padding-left: 40px !important;
      padding-top: 13px !important;
      font-weight: bold;
      color: #0d2222 !important;
  }

  .p-multiselect-open {
      background-color: #f0fafa;

      .p-multiselect-label {
          background-image: url(assets/images/view_column_green.svg);
          color: #00807d !important;
      }

      .p-multiselect-trigger {
          color: #00807d;
      }

      .p-multiselect-filter-container {
          border: none;
          outline: 0;

          .p-multiselect-filter {
              border: none;
              outline: 0;
          }

          .p-inputtext:enabled:focus {
              border: none;
              outline: 0;
              box-shadow: none;
          }
      }

      .p-multiselect-items-wrapper {
          max-height: 245px !important;
          min-width: 200px;
          border-radius: 6px;
      }

      .p-multiselect-close {
          margin-left: 0rem;
      }
  }
}

.rtl {
  .flex-item-custom-btn {
      .p-multiselect {
          padding-left: 8px;

          .p-multiselect-close {
              margin-right: 0.125rem;
          }
      }
  }
  // CSS for settings payments tab
  .default-payment-type-list {
    .p-dropdown-clearable {
      .p-dropdown-clear-icon {
        font-size: 1rem !important;
        left: 2.75rem !important;
        right: unset;
      }
    }
  }
}

.product-track-container #supplier-autocomplete span.p-autocomplete.p-component {
    width: 100%;
}

.rtl{
	.p-menu .p-menuitem-link .p-menuitem-icon{
		margin-left: 0.5rem !important;
	}
}

.help-menu-panel.p-overlaypanel {
  width: 14rem !important;

  &:before, &:after {
    content: none;
  }

  .p-overlaypanel-content {
    padding: 0.25rem 0 !important;
    box-shadow: 0px 2px 12px rgb(13 34 34 / 12%);
    border-radius: 4px;

    li {
      padding: 0.5rem 1rem 0.5rem 0.75rem;

      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #445555;
        display: flex;
        align-items: center;

        .label-icon {
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .fa-regular {
          font-size: 16px;
        }

        .label-shortcut {
          color: #9CABAB;
        }
      }
    }
  }
}

.word-break {
  word-break: break-word !important;
}

.word-break-all {
  word-break: break-all !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rtl {
  .help-menu-panel.p-overlaypanel {
    .label-icon {
      margin-left: 11px;
    }
  }
}

.ltr {
  .help-menu-panel.p-overlaypanel {
    .label-icon {
      margin-right: 11px;
    }
  }
}

.exp-rows-found .main-wrap-td {
  height: auto !important;
}
.add-customer-dialog{
  .p-dialog-title{
    text-transform: capitalize;
  }
}
.publish-product {
  &-list {
    margin-bottom: 0;
    overflow-y: auto;
    max-height: 400rem;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      border-radius: 25px;
      background-color: whitesmoke;
    }

    &::-webkit-scrollbar-thumb {
      background: lightgrey;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #53b6b6;
      border-radius: 25px;
    }
  }
  .product-message-list {
    margin-bottom: 0;
    li {
      margin-bottom: 12rem;
      font-size: 14rem;
      &:last-child {
        margin-bottom: 0;
      }
      i {
        font-size: 16rem;
      }
    }
  }
}
button{

  &.fa-circle-info{
    border: none;
    background: none;
    outline: none;
    &:focus {
      outline: none;
    }
    &:active {
      outline: none;
    }
  }
}

.blurred-background {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px); /* Adjust the blur value as needed */
}

.rtl{
  .p-calendar-clearable{
    .cm-datepicker .p-inputtext{
      padding-right: 0.75rem;
      padding-left:  2rem;
    }
  }
}

.pos-skeleton{
  display: none;
  width: 100%;
  height: calc(100% - 64px);
  background-color: #f6f8fa;

  @media screen and (max-width: 922px) {
    height: 100%;
  }

  &-show{
    display: flex;
    position: absolute;
    top: 0;    
    z-index: 10;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(#fff0, #f4f6f6fc)
    }
  }
}